const footer = $(`<div class="footer-main">
  <div class="box">
    <p class="title">分期消费 刷我滴卡</p>
    <div class="footer-logo"></div>
    <p class="tips">如果您希望享受客户服务、商务合作、媒体报道，请拨打客服电话：</p>
    <div class="tel-wrap">
      <div class="tel-icon"></div>
      <p class="tel">952592 （9:00-18:00）</div>
    </div>

    <div class="line"></div>
    <div class="tips-2"><i></i><p><a href="https://beian.miit.gov.cn" target="_blank">Copyright ©2022 粤ICP备19036328号</a></p></div>
  </div>
</div>`)
export default footer
